import type { PageApiData, PageDetailApi } from '~/server/types'

export const usePageStore = defineStore('Page', () => {
  const modules = ref<any[]>([])
  const pathData = ref<PageApiData>()
  const { config } = storeToRefs(useStore())
  async function fetchPageData(path = '/') {
    path = encodeURIComponent(path)
    const res = await useFetch(`/api/page?lang_id=${config.value.id}&path=${path}`)
    pathData.value = (res.data.value as PageDetailApi).data
    modules.value = pathData.value?.modules || []
  }
  function filterModule(name: string) {
    if (modules.value.length > 0) {
      const filters = modules.value.filter((module) => module.component_name === name)
      if (filters.length > 0) {
        const module = filters[0]
        return {
          ...module.detail,
          name: useRuntimeConfig().public.env.VITE_APP_NAME
        }
      } else {
        return null
      }
    }
    return null
  }
  return {
    modules,
    fetchPageData,
    filterModule
  }
})
